import { useState } from 'react';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import styled from 'styled-components';

import { Blurb, SkeletonBlurb } from 'components/Containers';
import UpdateAuthorizationModal from 'components/modals/UpdateAuthorizationModal';
import useIsMobile from 'hooks/useIsMobile';
import { parseDate, simpleDate } from 'lib/date';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { colors } from 'styles/theme/colors';
import { H3, Label, LabelBold } from 'styles/typography';
import AuthApprovedThroughIcon from 'svg/AuthApprovedThroughIcon';
import AuthValidThroughIcon from 'svg/AuthValidThroughIcon';
import NextReviewIcon from 'svg/NextReviewIcon';
import PatientAuthApprovedIcon from 'svg/PatientAuthApprovedIcon';
import PatientAuthNotAuthorizedIcon from 'svg/PatientAuthNotAuthorizedIcon';
import PencilIcon from 'svg/PencilIcon';

type AuthorizationType = {
  locationEpisode?: LocationEpisode;
  episode?: Episode;
  profile?: Profile;
  patientName?: string;
};
export function Authorization(props: AuthorizationType) {
  const { locationEpisode, episode, profile, patientName } = props;

  const [showModal, setShowModal] = useState(false);

  const isMobile = useIsMobile();

  if (!locationEpisode || !profile) {
    return (
      <Blurb>
        <DischargeHeader>
          <SkeletonBlank $width={200} $height={12} $spacing={10} />
        </DischargeHeader>
        <Number>
          <SkeletonBlank $width={140} $height={12} />
        </Number>
        <Approval>
          <SkeletonBlank $width={42} $height={42} />
          <ApprovalText>
            <SkeletonBlank $width={160} $height={17} $spacing={10} />
            <SkeletonBlank $width={200} $height={12} />
          </ApprovalText>
        </Approval>
      </Blurb>
    );
  }

  const authorization = locationEpisode.activeAuthorizationReview;
  if (!authorization) return <></>;

  const rehabState = locationEpisode.currentRehabState;

  const canUpdate =
    !episode?.archived && !locationEpisode.archived && profile.isAcute && profile.permissions.reviewAuthorizationEdit;
  const canBeModified =
    profile.actingClientId === locationEpisode.owner.clientId && profile.permissions.reviewAuthorizationEdit;

  const parsedApprovedThroughDate = parseDate(authorization.data.approvedThroughDate);

  const isActive = rehabState.queue && (authorization.accepted || authorization.pending);
  const isAccepted = !rehabState.queue && authorization.accepted;
  const isApproved = authorization.data.authorizationNumber && !parsedApprovedThroughDate && (isActive || isAccepted);
  const hasExpiry = !rehabState.queue && authorization.data.authorizationNumber && parsedApprovedThroughDate;
  const daysRemaining = differenceInCalendarDays(parsedApprovedThroughDate ?? new Date(), new Date());

  const showApprovedThrough = !rehabState.queue && (hasExpiry || (profile.isAcute && canBeModified));

  const showNextReview = authorization.data.nextReviewDate && !rehabState.queue;
  const showValidThroughDate = rehabState.queue && authorization.data.validThroughDate && isApproved;
  const showDateDetails = showApprovedThrough || showNextReview || showValidThroughDate;

  const statusContent = () => {
    if (isApproved) {
      return <StyledH3>Approved {rehabState.queue && <Label>(Pending Admission)</Label>}</StyledH3>;
    } else if (hasExpiry) {
      return <StyledH3>{`${Math.max(0, daysRemaining)} Day${daysRemaining === 1 ? '' : 's'} Remaining`}</StyledH3>;
    } else {
      return <StyledH3>Not Yet Approved</StyledH3>;
    }
  };

  return (
    <Blurb data-cy='authorizationCard'>
      <DischargeHeader>
        <H3>Authorization</H3>
        <Spacer />
        {canUpdate && (
          <ActionContainer data-cy='clickable' onClick={() => setShowModal(true)}>
            <PencilIcon width={12} height={12} color={colors.primaryBlue} />
            <StyledLabel>Update</StyledLabel>
          </ActionContainer>
        )}
      </DischargeHeader>
      <Approval>
        <ApprovalText>
          {statusContent()}
          <Label fontSize='14px'>
            {authorization.data.authorizationNumber ? `#${authorization.data.authorizationNumber}` : 'Pending Approval'}
          </Label>
        </ApprovalText>
        {!isMobile && (
          <>
            {isApproved || (hasExpiry && daysRemaining > 0) ? (
              <PatientAuthApprovedIcon />
            ) : (
              <PatientAuthNotAuthorizedIcon />
            )}
          </>
        )}
      </Approval>
      {showDateDetails && (
        <DateDetails>
          {showApprovedThrough && (
            <DateDetail data-cy='ApprovedThroughDateRow'>
              <LabelWithIcon>
                <AuthApprovedThroughIcon />
                Approved Through
              </LabelWithIcon>
              {parsedApprovedThroughDate ? (
                <Label fontSize='14px'>{simpleDate(parsedApprovedThroughDate)}</Label>
              ) : locationEpisode.archived ? (
                <>&mdash;</>
              ) : (
                <ActionText onClick={() => setShowModal(true)}>Select Date</ActionText>
              )}
            </DateDetail>
          )}

          {showNextReview && showApprovedThrough && <HR />}

          {showNextReview && (
            <DateDetail data-cy='NextReviewDateRow'>
              <LabelWithIcon>
                <NextReviewIcon />
                Next Review
              </LabelWithIcon>
              <Label fontSize='14px'>{simpleDate(parseDate(authorization.data.nextReviewDate))}</Label>
            </DateDetail>
          )}

          {showNextReview && showValidThroughDate && <HR />}

          {showValidThroughDate && (
            <DateDetail data-cy='ValidThroughDateRow'>
              <LabelWithIcon>
                <AuthValidThroughIcon />
                Valid Through
              </LabelWithIcon>
              <ValidThroughDateLabel
                fontSize='14px'
                $pastDue={parseISO(format(new Date(), 'yyyy-MM-dd')) > parseISO(authorization.data.validThroughDate)}>
                {simpleDate(parseDate(authorization.data.validThroughDate))}
              </ValidThroughDateLabel>
            </DateDetail>
          )}
        </DateDetails>
      )}
      {showModal && (
        <UpdateAuthorizationModal
          profile={profile}
          setShow={setShowModal}
          patientName={patientName!}
          locationEpisode={locationEpisode}
        />
      )}
    </Blurb>
  );
}

const DischargeHeader = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;

  h3 {
    font-size: 14px;
  }
`;

const Number = styled.div`
  padding: 0 24px 24px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
  color: var(--primary-blue);
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Approval = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-top: 1px solid var(--black-10);
`;

const DateDetails = styled.div`
  padding: 0 24px 8px;
  display: flex;
  flex-direction: column;
`;

const HR = styled.hr`
  border: none;
  height: 1px;
  background-color: ${colors.black10};
`;

const ApprovalText = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 8px;
  line-height: normal;

  label {
    font-weight: normal;
  }
`;

const LabelWithIcon = styled(Label)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;

  svg {
    margin-right: 10px;
  }
`;

const ValidThroughDateLabel = styled(Label)<{ $pastDue: boolean }>`
  color: ${({ $pastDue }) => ($pastDue ? colors.accentRed : colors.black)};
`;

const DateDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

const ActionText = styled(LabelBold)`
  cursor: pointer;
  color: ${colors.primaryBlue};
  font-size: 14px;
`;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
  $spacing?: number;
}>`
  border-radius: ${({ $height }) => $height + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
  margin-bottom: ${({ $spacing }) => ($spacing ?? 0) + 'px'};
`;
