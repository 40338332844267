import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import useInvalidatedPatient from 'components/patient/useInvalidatedPatientQueries';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import StandardModal, { ColorThemeName, PatientName } from 'components/shared/StandardModal';
import { deleteLocationEpisode } from 'services/api/locationEpisodes';
import { useToastActions } from 'stores/toastStore';
import { BodySmall, BodySmallBold } from 'styles/typography';

export type CancelRecordModalProps = {
  patientName: string;
  groupName: string;
  locationEpisodeId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export default function CancelRecordModal({
  patientName,
  groupName,
  locationEpisodeId,
  onCancel,
  onSuccess,
}: CancelRecordModalProps) {
  const invalidatePatientQueries = useInvalidatedPatient();
  const { addToast } = useToastActions();
  const { mutate: cancelRecord, isPending } = useMutation({
    mutationFn: deleteLocationEpisode,
  });

  const confirmRemovePatient = async () => {
    cancelRecord(
      { id: locationEpisodeId },
      {
        onSuccess: () => {
          onSuccess();
          addToast({ text: `Record at ${groupName} has been successfully canceled` });
          invalidatePatientQueries({
            episode: true,
            locationEpisodes: true,
            activities: true,
          });
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
      }
    );
  };

  return (
    <StandardModal
      closeOnBackdropClick
      colorTheme={ColorThemeName.PrimaryRed}
      onCancel={onCancel}
      title='Confirm Cancelation'>
      <Content>
        <PatientName style={{ marginBottom: 0 }}>Patient: {patientName}</PatientName>
        <BodySmallBold>
          Canceling a record will remove the patient from the Patient Portfolio for {groupName}.
        </BodySmallBold>
        <BodySmall>This will disable the ability to add notes to the patient&apos;s story at {groupName}</BodySmall>
      </Content>
      <StyledButtonGroup>
        <Button variant='ghost' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='danger' onClick={confirmRemovePatient} loading={isPending}>
          Confirm Cancelation
        </Button>
      </StyledButtonGroup>
    </StandardModal>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const Content = styled.div`
  margin: 26px 0 50px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
