import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants } from '../../../constants';
import Card from '../../Card';
import { AnalyticsQueryParams } from '../../helpers/useAnalyticsInfiniteQuery';
import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type AlosTableRow = CategoryRow & {
  alos: { current: number; diff: number | null };
};

type Props = {
  chartData: {
    categories: string[];
    values: number[];
    counts: number[];
    average: number;
  };
  priorPeriodChartData: {
    values: number[];
  };
  selectedDimension: string;
  totalLocationEpisodes: number;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
};

const AverageLengthOfStayDataTable = ({
  chartData,
  priorPeriodChartData,
  selectedDimension,
  totalLocationEpisodes,
  loading,
  requestParams,
}: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const tableData = useMemo<AlosTableRow[]>(() => {
    const { categories, values, counts } = chartData;

    return categories.map((categoryName, i) => ({
      categoryName,
      alos: {
        current: values[i],
        diff: priorPeriodChartData.values[i] !== null ? values[i] - priorPeriodChartData.values[i] : null,
      },
      locationEpisodes: counts[i],
    }));
  }, [chartData, priorPeriodChartData.values]);

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      case DimensionConstants.REASON:
        return 'Disposition';
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<AlosTableRow>();
  const columns = useMemo<ColumnDef<AlosTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${totalLocationEpisodes} (Total)`,
      }),
      columnHelper.accessor('alos', {
        header: 'Average Length of Stay',
        cell: (data) => (
          <>
            <p>{`${data.getValue().current.toFixed(1)} days`}</p>{' '}
            <MetricDiffBadge
              diff={data.getValue().diff}
              comparator={Comparator.LESS_THAN}
              formatter={(val) => `${val.toFixed(1)}`}
            />
          </>
        ),
        footer: () => `${chartData.average.toFixed(1)} days (Avg)`,
      }),
    ],
    [chartData.average, columnHelper, getTableHeader, totalLocationEpisodes]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'locationEpisodes',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Average Length of Stay Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='average-length-of-stay-export'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default AverageLengthOfStayDataTable;
