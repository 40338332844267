import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import StandardModal, { ColorThemeName } from 'components/shared/StandardModal';
import { PatientName } from 'components/shared/StandardModal';
import { deleteEpisode } from 'services/api/episode';
import { useToastActions } from 'stores/toastStore';
import { H3 } from 'styles/typography';

export type ConfirmPatientRemovalModalProps = {
  patientName: string;
  episodeId: string;
  onCancel: () => void;
};

export default function ConfirmPatientRemovalModal({
  patientName,
  episodeId,
  onCancel,
}: ConfirmPatientRemovalModalProps) {
  const navigate = useNavigate();
  const { addToast } = useToastActions();

  const { mutate: removePatient, isPending } = useMutation({
    mutationFn: deleteEpisode,
  });

  const confirmRemovePatient = async () => {
    removePatient(
      { id: episodeId! },
      {
        onSuccess: () => {
          addToast({ text: `${patientName} has been successfully removed.` });
          navigate('/patients');
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again' });
        },
      }
    );
  };

  return (
    <StandardModal colorTheme={ColorThemeName.Danger} onCancel={onCancel} title='Confirm Patient Removal'>
      <PatientName>Patient: {patientName}</PatientName>
      <Content>
        <H3>Are you sure you want to remove the patient from Olio?</H3>
        <br />
        <Paragraph>
          Once a patient is removed, they can no longer be accessed on the Patient Portfolio or All Patients page.
        </Paragraph>
      </Content>
      <StyledButtonGroup>
        <Button variant='ghost' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='danger' onClick={confirmRemovePatient} loading={isPending}>
          Remove Patient
        </Button>
      </StyledButtonGroup>
    </StandardModal>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const Content = styled.div`
  margin: 26px 0 50px;
`;

const Paragraph = styled.p`
  display: block;
`;
