import styled from 'styled-components';

import colors from 'styles/theme/colors';

const MenuDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.black05};
`;

export default MenuDivider;
