import React, { createContext, useContext, useRef, useState } from 'react';

import { useClickOutside } from '@react-awesome/use-click-outside';

import MenuContent from 'components/shared/menu/menu-content';
import MenuDivider from 'components/shared/menu/menu-divider';
import MenuIcon from 'components/shared/menu/menu-icon';
import MenuItem from 'components/shared/menu/menu-item';
import MenuTrigger from 'components/shared/menu/menu-trigger';

export type MenuSize = 'default' | 'small' | 'large';

type MenuContextType = {
  open: boolean;
  toggle: (value?: boolean) => void;
  triggerRef: React.RefObject<HTMLElement>;
  menuRef: React.RefObject<HTMLDivElement>;
  $size: MenuSize;
};

type MenuProps = {
  children?: React.ReactElement<typeof MenuContent | typeof MenuTrigger>[];
  $size?: MenuSize;
};

export const MenuContext = createContext<MenuContextType>({
  open: false,
  toggle: () => ({}),
  triggerRef: { current: null },
  menuRef: { current: null },
  $size: 'default',
});

export const useMenuContext = () => useContext(MenuContext);

function Menu({ children, $size = 'default' }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggle = (value?: boolean) => {
    if (value !== undefined) {
      setIsOpen(value);
      return;
    }

    setIsOpen((prev) => !prev);
  };

  useClickOutside(triggerRef.current || menuRef.current, () => {
    if (isOpen) {
      toggle();
    }
  });

  return (
    <MenuContext.Provider value={{ open: isOpen, toggle, triggerRef, menuRef, $size }}>{children}</MenuContext.Provider>
  );
}

Menu.Item = MenuItem;
Menu.Trigger = MenuTrigger;
Menu.Content = MenuContent;
Menu.Divider = MenuDivider;
Menu.Icon = MenuIcon;
export default Menu;
