import { useState } from 'react';

import CancelRecordModal from 'components/modals/CancelRecordModal';
import ConfirmPatientRemovalModal from 'components/modals/ConfirmPatientRemovalModal';
import Button from 'components/shared/Button';
import Menu from 'components/shared/menu';
import { useProfile } from 'context/profile';
import Episode from 'models/Episode';
import colors from 'styles/theme/colors';
import CircleXIconOutlined from 'svg/CircleXIconOutlined';
import EllipsisIcon from 'svg/EllipsisIcon';
import TrashIcon from 'svg/TrashIcon';

type Props = {
  episode: Episode;
};

export default function PatientActionsMenu({ episode }: Props) {
  const { profile } = useProfile();
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);
  const [showCancelRecord, setShowCancelRecord] = useState(false);

  const latestLocationEpisode = episode.latestLocationEpisode;

  // We only allow cancelation of the LATEST location episode
  // as long as it is not the only one and it is not archived or discharged
  const locationEpisodeEligibleForCancelation =
    episode.locationEpisodes.length > 1 && !latestLocationEpisode?.archived && !latestLocationEpisode?.discharged;

  const canCancelRecord = locationEpisodeEligibleForCancelation && !!profile.permissions.locationEpisodeDelete;
  const canRemovePatient = !!profile.permissions.patientDelete;
  const showActionsMenu = canCancelRecord || canRemovePatient;

  return (
    <>
      {showActionsMenu && (
        <Menu $size='large'>
          <Menu.Trigger>
            <Button
              variant='outline-gray'
              style={{ width: 44, padding: 0, height: 44, flexShrink: 0 }}
              data-cy='patientActionsMenuButton'>
              <EllipsisIcon />
            </Button>
          </Menu.Trigger>
          <Menu.Content position='right' style={{ marginTop: 4 }}>
            {canCancelRecord && (
              <Menu.Item onClick={() => setShowCancelRecord(true)}>
                <Menu.Icon>
                  <CircleXIconOutlined width={14} height={14} />
                </Menu.Icon>
                Cancel record at {latestLocationEpisode?.rehabInformation.latestRehabFacility.name}
              </Menu.Item>
            )}

            {canCancelRecord && canRemovePatient && <Menu.Divider />}

            {canRemovePatient && (
              <Menu.Item style={{ color: colors.accentRed }} onClick={() => setShowRemovePatientModal(true)}>
                <Menu.Icon>
                  <TrashIcon width={14} height={14} />
                </Menu.Icon>
                Remove patient from Olio
              </Menu.Item>
            )}
          </Menu.Content>
        </Menu>
      )}

      {showRemovePatientModal && (
        <ConfirmPatientRemovalModal
          episodeId={episode.id}
          patientName={episode.patient.name}
          onCancel={() => setShowRemovePatientModal(false)}
        />
      )}
      {showCancelRecord && latestLocationEpisode && (
        <CancelRecordModal
          locationEpisodeId={latestLocationEpisode.id}
          patientName={episode.patient.name}
          groupName={latestLocationEpisode.rehabInformation.latestRehabFacility?.name || ''}
          onCancel={() => setShowCancelRecord(false)}
          onSuccess={() => setShowCancelRecord(false)}
        />
      )}
    </>
  );
}
