import { ownerLocationTypesToShowHospitalField } from 'components/intake/intakeFormValidation';
import RehabFacilityInputGroup from 'components/intake/RehabFacilityInputGroup';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import AssignToMe from 'components/shared/form/AssignToMe';
import DatePicker from 'components/shared/form/DatePicker';
import FormSection from 'components/shared/form/FormSection';
import { MultiSelectChips, Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import SectionHeader from 'components/shared/form/SectionHeader';
import { Flags } from 'constants/flags';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP, PHYSICIAN_TEAM } from 'constants/locationTypes';
import { useForm } from 'context/form';
import { useAddressOptions } from 'hooks/useAddressOptions';
import { parseDate } from 'lib/date';
import AttrValue from 'models/AttrValue';
import Classification, { ClassificationApiName } from 'models/Classification';
import { ClientType } from 'models/Client';
import Group from 'models/Group';
import { PERMISSIONS } from 'models/Profile';
import Profile from 'models/Profile';
import User from 'models/User';
import { useInfiniteAttrValues } from 'services/api/attrValue';
import { useInfiniteClassifications } from 'services/api/classification';
import { useInfiniteGroups } from 'services/api/group';
import { useInfiniteUsers } from 'services/api/user';

export type EpisodeInfoSectionProps = {
  profile: Profile;
};

export function getLocationTypeForOwningClient(clientType: ClientType) {
  return clientType == ClientType.HEALTH_SYSTEM
    ? HOSPITAL
    : clientType == ClientType.PHYSICIAN_GROUP
      ? PHYSICIAN_GROUP
      : PAYER;
}
export function getOwnerText(clientType: ClientType) {
  return clientType == ClientType.HEALTH_SYSTEM
    ? 'Hospital'
    : clientType == ClientType.PHYSICIAN_GROUP
      ? 'Physician Group'
      : 'Payer';
}

function EpisodeInfoSection({ profile }: EpisodeInfoSectionProps) {
  const { values, meta, errors, setValue, setValues } = useForm<FormValues, FormMeta>();

  const clientType = profile?.clientType;
  const locationTypeForOwningClient = getLocationTypeForOwningClient(clientType);
  const ownerText = getOwnerText(clientType);

  const canAssignUtilizationManager =
    profile?.hasFlag(Flags.UtilizationManagerAssignment) &&
    profile?.has(PERMISSIONS.locationEpisodeUserEdit) &&
    !meta.latestLocationEpisodeIsArchived;

  const canAssignCaseManager =
    profile?.hasFlag(Flags.CaseManagerAssignment) &&
    profile?.has(PERMISSIONS.locationEpisodeUserEdit) &&
    !meta.latestLocationEpisodeIsArchived;

  const isPostAcuteUser = profile?.isPostAcute;
  const ownerIsSelected = values.owner?.id;
  const showHospitalDischargeDate = profile?.isAcute && meta.mode == PatientFormMode.Connecting;

  const asyncEpisodeTypeOptions = useInfiniteClassifications({
    client: values.owner?.clientId,
    type: ClassificationApiName.EPISODE,
    sortBy: 'name',
  });

  const asyncPlanTypeOptions = useInfiniteClassifications({
    client: values.owner?.clientId,
    type: ClassificationApiName.PLAN_TYPE,
    sortBy: 'name',
  });

  const asyncPhysicianTeamOptions = useInfiniteAttrValues({
    type: PHYSICIAN_TEAM,
    distinctDisplayName: true,
  });

  const asyncUserOptions = useInfiniteUsers({
    group: values.owner?.id,
    include: 'credential',
    sortBy: 'name',
    active: true,
  });

  const asyncOwnerOptions = useInfiniteGroups({
    type: locationTypeForOwningClient,
    sortBy: 'name',
  });

  const asyncHospitalOptions = useInfiniteGroups({
    ownerId: values.owner?.id,
    type: HOSPITAL,
    sortBy: 'name',
  });

  const addressProps = useAddressOptions();

  const handleOwnerChange = (change: Group) => {
    setValues(
      {
        caseManager: null,
        utilizationManager: null,
        hospital: null,
        owner: change,
      },
      { validate: true }
    );
    setValue('rehabFacility', null, { validate: false });
  };

  const getSelectedCountLabel = (selectedItems: any[] | undefined) => {
    return (selectedItems ?? []).length ? ` - ${selectedItems?.length} selected` : '';
  };

  return (
    <FormSection>
      <SectionHeader>Episode Information</SectionHeader>
      <InputGroup title='Hospital Admission Date (optional)'>
        <DatePicker
          maxDate={new Date()}
          selected={parseDate(values.admittedOn)}
          onChange={(change) => setValue('admittedOn', change?.toISOString())}
          disabled={isPostAcuteUser}
        />
      </InputGroup>
      {showHospitalDischargeDate ? (
        <InputGroup title='Anticipated Hospital Discharge (optional)' error={errors.antHospitalDischarge}>
          <DatePicker
            selected={parseDate(values.antHospitalDischarge)}
            onChange={(change) => setValue('antHospitalDischarge', change?.toISOString())}
            disabled={false}
          />
        </InputGroup>
      ) : null}
      <InputGroup title={ownerText} error={errors.owner} data-cy='owner'>
        <Select<Group>
          {...asyncOwnerOptions}
          {...addressProps}
          name='owner'
          onChange={handleOwnerChange}
          value={values.owner}
          placeholder={ownerText}
          getOptionValue={(prop) => prop.id}
          hasError={!!errors.owner}
          disabled={isPostAcuteUser}
        />
      </InputGroup>
      {canAssignUtilizationManager && (
        <InputGroup title='Utilization Manager (optional)' data-cy='utilizationManager'>
          <Select<User>
            {...asyncUserOptions}
            name='utilizationManager'
            value={values.utilizationManager}
            onChange={(value) => setValue('utilizationManager', value)}
            disabled={!ownerIsSelected}
            placeholder='Utilization Manager'
            getOptionValue={(prop) => prop.id}
            getOptionLabel={(prop) => prop.fullName}
            isClearable
          />
          {ownerIsSelected && <AssignToMe handleAssign={() => setValue('utilizationManager', profile)} />}
        </InputGroup>
      )}
      {canAssignCaseManager && (
        <InputGroup title='Case Manager (optional)' data-cy='caseManager'>
          <Select<User>
            {...asyncUserOptions}
            name='caseManager'
            value={values.caseManager}
            onChange={(value) => setValue('caseManager', value)}
            disabled={!ownerIsSelected}
            placeholder='Case Manager'
            getOptionValue={(prop) => prop.id}
            getOptionLabel={(prop) => prop.fullName}
            isClearable
          />
          {ownerIsSelected && <AssignToMe handleAssign={() => setValue('caseManager', profile)} />}
        </InputGroup>
      )}
      {ownerLocationTypesToShowHospitalField.includes(locationTypeForOwningClient) && (
        <InputGroup
          title={`Hospital ${getLocationTypeForOwningClient(clientType) === PAYER ? '(optional)' : ''}`}
          error={errors.hospital}
          data-cy='hospital'>
          <Select<Group>
            {...asyncHospitalOptions}
            name='hospital'
            placeholder='Hospital'
            value={values.hospital}
            onChange={(value) => setValue('hospital', value)}
            disabled={isPostAcuteUser || !values.owner?.id}
            getOptionValue={(prop) => prop.id}
            getOptionLabel={(prop) => prop.name}
            hasError={!!errors.hospital}
            isClearable={getLocationTypeForOwningClient(clientType) === PAYER}
          />
        </InputGroup>
      )}
      <InputGroup
        title={`Physician ${getLocationTypeForOwningClient(clientType) === PAYER ? '(optional)' : ''}`}
        error={errors.physicianTeam}
        data-cy='physicianTeam'>
        <Select<AttrValue>
          {...asyncPhysicianTeamOptions}
          name='physicianTeam'
          placeholder='Physician'
          value={values.physicianTeam}
          onChange={(change) => setValue('physicianTeam', change)}
          disabled={isPostAcuteUser || !values.owner?.id}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          hasError={!!errors.physicianTeam}
          isClearable={getLocationTypeForOwningClient(clientType) === PAYER}
        />
      </InputGroup>
      <RehabFacilityInputGroup profile={profile} />
      <SectionHeader>Plan Type + Episode Information</SectionHeader>
      <InputGroup title='Plan Type (optional)' data-cy='planTypeClassification'>
        <Select<Classification>
          {...asyncPlanTypeOptions}
          name='planTypeClassification'
          placeholder='Plan Type'
          onChange={(change) => setValue('planTypeClassification', change)}
          value={values.planTypeClassification}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          disabled={isPostAcuteUser || !values.owner?.id}
          isClearable
        />
      </InputGroup>
      <InputGroup
        title={`Episode Type (optional)${getSelectedCountLabel(values.episodeClassifications ?? [])}`}
        data-cy='episodeClassifications'>
        <MultiSelectChips<Classification>
          {...asyncEpisodeTypeOptions}
          name='episodeClassifications'
          values={values.episodeClassifications ?? []}
          onChange={(change) => setValue('episodeClassifications', change)}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          disabled={isPostAcuteUser || !values.owner?.id}
          isClearable
        />
      </InputGroup>
    </FormSection>
  );
}

export default EpisodeInfoSection;
