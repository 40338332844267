import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { activityQueryKeys } from 'services/api/activity';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { episodeQueryKeys } from 'services/api/episode';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';

type InvalidateOptions = {
  episode?: boolean;
  locationEpisodes?: boolean;
  activities?: boolean;
  attachmentsCount?: boolean;
};

export default function useInvalidatedPatientQueries() {
  const queryClient = useQueryClient();

  return useCallback(
    ({
      episode = false,
      locationEpisodes = false,
      activities = false,
      attachmentsCount = false,
    }: InvalidateOptions = {}) => {
      if (episode) queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show() });
      if (locationEpisodes) queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show() });
      if (activities) queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      if (attachmentsCount) queryClient.invalidateQueries({ queryKey: attachmentsQueryKeys.count() });
    },
    [queryClient]
  );
}
