import styled from 'styled-components';

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto; // prevents icon from being squished
`;

export default MenuIcon;
