import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  DimensionConstants,
  MetricConstants,
  RouteConstants,
  SortOrder,
  SourceConstants,
} from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import { generateExtendedChartConfig } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import {
  groupByDateIndex,
  mapPreviousDataToCurrentCategories,
  parseDimensionValues,
  parseMetricValues,
} from 'components/insights/layout/helpers/dataUtils';
import Histogram from 'components/shared/charts/Histogram';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import useInsightsQuery from '../../helpers/useInsightsQuery';
import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

import ActivitiesPerPatientWeekDataTable from './ActivitiesPerPatientWeekDataTable';

const ActivitiesPerPatientWeekDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );

  const requestParams = useMemo(
    () => ({
      source: SourceConstants.LOCATION_EPISODE_DAYS,
      dimensions: [selectedDimension],
      metrics: [
        MetricConstants.ACTIVITIES_PER_PATIENT_PER_WEEK,
        MetricConstants.LOCATION_EPISODE_ID_COUNT,
        MetricConstants.OWNER_ACTIVITIES_SUM,
      ],
      sortBy: `${MetricConstants.LOCATION_EPISODE_ID_COUNT} ${SortOrder.DESC}`,
    }),
    [selectedDimension]
  );

  const request = useMemo(
    () => ({
      params: {
        ...requestParams,
        rollups: true,
      },
      processData: (unprocessedData: Analytics) => {
        const groupedByDateIndex = groupByDateIndex(unprocessedData);

        if (!groupedByDateIndex.length) {
          return null;
        }

        const parsedData = groupedByDateIndex.map((row) => {
          return row.reduce(
            (acc, row) => {
              const dimensionValues = parseDimensionValues(row) as string[];
              const metricValues = parseMetricValues(row);
              const groupName = dimensionValues[0];
              const activitiesPerPatientPerWeek = metricValues[0];
              const patients = metricValues[1];
              const activities = metricValues[2];
              if (row.grouping === 1) {
                acc.average = activitiesPerPatientPerWeek;
                acc.totalPatients = patients;
                acc.totalActivities = activities;
              } else {
                acc.categories.push(groupName || 'None');
                acc.values.push(activitiesPerPatientPerWeek);
                acc.activities.push(activities);
                acc.patients.push(patients);
              }
              return acc;
            },
            {
              categories: [],
              values: [],
              average: 0,
              patients: [],
              activities: [],
              totalPatients: 0,
              totalActivities: 0,
            } as {
              categories: string[];
              values: number[];
              average: number;
              patients: number[];
              activities: number[];
              totalPatients: number;
              totalActivities: number;
            }
          );
        });
        // We can assume that the first index is always going to be the chosen date range dataset and the most of the display data will come from that.
        const { totalPatients, totalActivities, ...chartData } = parsedData[0];

        // It is possible that this is undefined
        const priorPeriod = parsedData[1];

        const priorPeriodValues = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('values', chartData, priorPeriod)
          : [];

        return {
          chartData,
          totalPatients,
          totalActivities,
          priorPeriodChartData: { values: priorPeriodValues, average: priorPeriod?.average ?? null }, // 0 is a valid value
        };
      },
    }),
    [requestParams, comparePriorPeriod]
  );
  const { loading, query } = useInsightsQuery(request);

  const {
    chartData = { categories: [], values: [], average: 0, patients: [] },
    priorPeriodChartData = { values: [], average: null },
    totalActivities,
    totalPatients,
  } = query.data || {};

  const chartConfig = useMemo(
    () => generateExtendedChartConfig(chartData, priorPeriodChartData),
    [chartData, priorPeriodChartData]
  );

  return (
    <>
      {profile.hasFlag(Flags.Insights) && <ComparePriorPeriodToggle />}
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={
          <>
            <MetricDetailHeader
              label='Activities Per Patient Per Week'
              loading={loading}
              hasData={!!chartData.values.length}
              value={
                <>
                  {`${chartData.average.toFixed(1)}`}
                  <MetricDiffBadge
                    diff={
                      priorPeriodChartData.average !== null ? chartData.average - priorPeriodChartData.average : null
                    }
                    comparator={Comparator.GREATER_THAN}
                    formatter={(val) => `${val.toFixed(1)}`}
                  />
                </>
              }
            />
            <Separator />
            <MetricDetailHeader
              label='Activities'
              hasData={!!chartData.values.length}
              loading={loading}
              value={totalActivities > 0 ? totalActivities.toString() : '0'}
            />
            <Separator />
            <MetricDetailHeader
              label='Patients'
              hasData={!!chartData.values.length}
              loading={loading}
              value={totalPatients > 0 ? totalPatients.toString() : '0'}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <ActivitiesPerPatientWeekDataTable
        chartData={chartData}
        priorPeriodChartData={priorPeriodChartData}
        totalActivities={totalActivities}
        totalPatients={totalPatients}
        selectedDimension={selectedDimension}
        loading={loading}
        requestParams={requestParams}
      />
    </>
  );
};

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

export default ActivitiesPerPatientWeekDetail;
