import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants } from '../../../constants';
import Card from '../../Card';
import { AnalyticsQueryParams } from '../../helpers/useAnalyticsInfiniteQuery';
import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

type Props = {
  chartData: {
    categories: string[];
    values: number[];
  };
  priorPeriodChartData: { values: number[] };
  selectedDimension: any;
  totalDischarges: number;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
};

const TotalDischargesDataTable = ({
  chartData,
  priorPeriodChartData,
  selectedDimension,
  totalDischarges,
  loading,
  requestParams,
}: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const tableData = useMemo<CategoryRow[]>(() => {
    const categories = chartData.categories;
    const values = chartData.values;
    const priorValues = priorPeriodChartData.values;

    return categories.map((categoryName, i) => ({
      categoryName,
      discharges: {
        current: values[i],
        diff: priorValues[i] !== null ? values[i] - priorValues[i] : null,
      },
    }));
  }, [chartData, priorPeriodChartData]);

  const columnHelper = createColumnHelper<CategoryRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      case DimensionConstants.REASON:
        return 'Disposition';
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<CategoryRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('discharges', {
        header: 'Discharges',
        cell: (data) => (
          <>
            <p>{`${data.getValue().current.toFixed()}`}</p>{' '}
            <MetricDiffBadge
              diff={data.getValue().diff}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed()}`}
            />
          </>
        ),
        footer: () => `${totalDischarges} (Total)`,
      }),
    ],
    [columnHelper, getTableHeader, totalDischarges]
  );

  const defaultSort = [
    {
      id: 'discharges',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Total Discharges Overview</Card.Title>
        <ApiExport
          disabled={!tableData.length}
          show={!loading}
          params={requestParams}
          filename='total-discharges-export'
        />
      </TableTitleContainer>
      <DataTable columns={columns} data={tableData} defaultSortBy={defaultSort} loading={loading} />
    </TableContainer>
  );
};

export default TotalDischargesDataTable;
