import React from 'react';
import styled, { css } from 'styled-components';

import { MenuSize, useMenuContext } from 'components/shared/menu';

export interface MenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  $active?: boolean;
}

export default function MenuItem({ children, $active = false, onClick, ...rest }: MenuItemProps) {
  const { toggle, $size } = useMenuContext();

  // custom onClick handler to close the menu after calling the original onClick handler
  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick?.(e);
    toggle();
  };

  return (
    <ItemContainer $active={$active} onClick={handleItemClick} $size={$size} {...rest}>
      {children}
    </ItemContainer>
  );
}

const sizeStyles = (size: MenuSize = 'default') =>
  ({
    large: css`
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 14px;
    `,
    default: css`
      font-size: 14px;
    `,
    small: css`
      font-size: 12px;
    `,
  })[size];

const ItemContainer = styled.div<{ $active?: boolean; $size?: MenuSize }>`
  padding: 10px 12px;
  cursor: pointer;
  background-color: ${({ theme, $active }) => ($active ? theme.colors.primaryBlue : theme.colors.white)};
  color: ${({ theme, $active }) => ($active ? theme.colors.white : theme.colors.black)};
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $size }) => sizeStyles($size)}

  &:hover {
    background-color: ${({ theme, $active }) => ($active ? theme.colors.primaryBlue : theme.colors.primaryBlue15)};
  }
`;
