import { AFFILIATE, PHYSICIAN_TEAM } from 'constants/locationTypes';
import Episode from 'models/Episode';
import Group from 'models/Group';
import { GroupTypeOptions } from 'models/GroupType';
import User, { getDefaults as getUserModelDefaults, UserOptions } from 'models/User';
import InsightsFilter from 'models/userPreferences/InsightsFilter';
import PortfolioFilter from 'models/userPreferences/PortfolioFilter';

export const PERMISSIONS = {
  adminAttrView: 'adminAttrView',
  adminAttrCreate: 'adminAttrCreate',
  adminAttrEdit: 'adminAttrEdit',
  adminAttrValueView: 'adminAttrValueView',
  adminAttrValueCreate: 'adminAttrValueCreate',
  adminAttrValueEdit: 'adminAttrValueEdit',
  adminClassificationCreate: 'adminClassificationCreate',
  adminClassificationEdit: 'adminClassificationEdit',
  adminClassificationView: 'adminClassificationView',
  adminClientCreate: 'adminClientCreate',
  adminClientEdit: 'adminClientEdit',
  adminClientView: 'adminClientView',
  adminClientGroupTypeEdit: 'adminClientGroupTypeEdit',
  adminClientGroupTypeView: 'adminClientGroupTypeView',
  adminCollaborationEdit: 'adminCollaborationEdit',
  adminCollaborationView: 'adminCollaborationView',
  adminFeatureFlagEdit: 'adminFeatureFlagEdit',
  adminFeatureFlagView: 'adminFeatureFlagView',
  adminGroupCreate: 'adminGroupCreate',
  adminGroupDelete: 'adminGroupDelete',
  adminGroupEdit: 'adminGroupEdit',
  adminGroupView: 'adminGroupView',
  adminGroupTypeCreate: 'adminGroupTypeCreate',
  adminGroupTypeEdit: 'adminGroupTypeEdit',
  adminGroupTypeView: 'adminGroupTypeView',
  adminImportedPatientEdit: 'adminImportedPatientEdit',
  adminImportedPatientView: 'adminImportedPatientView',
  adminImportConfigurationCreate: 'adminImportConfigurationCreate',
  adminImportConfigurationEdit: 'adminImportConfigurationEdit',
  adminImportConfigurationView: 'adminImportConfigurationView',
  adminQuestionTemplateCreate: 'adminQuestionTemplateCreate',
  adminQuestionTemplateView: 'adminQuestionTemplateView',
  adminQuestionTemplateEdit: 'adminQuestionTemplateEdit',
  adminUserCreate: 'adminUserCreate',
  adminUserEdit: 'adminUserEdit',
  adminUserInvitationCreate: 'adminUserInvitationCreate',
  adminUserDelete: 'adminUserDelete',
  adminUserView: 'adminUserView',
  activityNoteCreate: 'activityNoteCreate',
  activityProgressUpdateCreate: 'activityProgressUpdateCreate',
  escalationCreate: 'escalationCreate',
  escalationEdit: 'escalationEdit',
  locationEpisodeDelete: 'locationEpisodeDelete',
  locationEpisodeUserEdit: 'locationEpisodeUserEdit',
  patientCreate: 'patientCreate',
  patientDelete: 'patientDelete',
  patientEdit: 'patientEdit',
  rehabStateAdmissionCreate: 'rehabStateAdmissionCreate',
  rehabStateDischargeCreate: 'rehabStateDischargeCreate',
  rehabStateEdit: 'rehabStateEdit',
  reviewAuthorizationEdit: 'reviewAuthorizationEdit',
  reviewProjectedDischargeEdit: 'reviewProjectedDischargeEdit',
  reviewServiceRefusalCreate: 'reviewServiceRefusalCreate',
  reviewServiceRefusalEdit: 'reviewServiceRefusalEdit',
  reviewAltcsApplicationEdit: 'reviewAltcsApplicationEdit',
};

export interface ProfileOptions extends UserOptions {
  selectedGroupIds: string[];
  allowedGroupIds: string[];
  isAcute: boolean;
  isAdmin: boolean;
  acceptedTermsOfService: boolean;
  enabledProviderTypes: GroupTypeOptions[];
}

function getDefaults(): ProfileOptions {
  return {
    ...getUserModelDefaults(),
    acceptedTermsOfService: false,
    allowedGroupIds: [],
    isAdmin: false,
    isAcute: false,
    selectedGroupIds: [],
  };
}

/**
 * @class Profile
 * @extends User
 * @classdesc Represents a user profile in the system
 * @property {string[]} selectedGroupIds - The profile's selected location ids
 * @property {string[]} allowedGroupIds - The profile's allowed location ids
 * @property {boolean} isAcute - Whether the profile is acute
 * @property {boolean} isAdmin - Whether the profile is an admin
 * @property {boolean} acceptedTermsOfService - Whether the profile has accepted the terms of service
 * @param {Partial<ProfileOptions>} [options={}]
 * @example const profile = new Profile({ id: '123' });
 */

export default class Profile extends User {
  acceptedTermsOfService: boolean;
  allowedGroupIds: string[];
  isAcute: boolean;
  isAdmin: boolean;
  selectedGroupIds: string[];

  constructor(options?: Partial<ProfileOptions>) {
    const opts = { ...getDefaults(), ...(options ?? {}) };

    super(opts);

    this.selectedGroupIds = opts.selectedGroupIds;
    this.isAcute = opts.actingAsAcute || opts.isAcute;
    this.isAdmin = opts.isAdmin;
    this.acceptedTermsOfService = opts.acceptedTermsOfService;
    this.allowedGroupIds = opts.allowedGroupIds;
    this.groupType = opts.groupType;
  }

  get insightsPreference() {
    return this.preferences?.find(
      (pref): pref is InsightsFilter => pref.clientId === this.actingClient?.id && pref instanceof InsightsFilter
    );
  }

  get portfolioPreference() {
    return this.preferences?.find(
      (pref): pref is PortfolioFilter => pref.clientId === this.actingClient?.id && pref instanceof PortfolioFilter
    );
  }

  get isPostAcute() {
    return !this.isAcute;
  }

  get isPhysicianTeamUser() {
    return this.groupType === PHYSICIAN_TEAM;
  }

  get clientType() {
    return this.actingClient.clientType;
  }

  get canCreatePatient() {
    if (this.isAffiliateUser) return false;

    return this.has(PERMISSIONS.patientCreate);
  }

  canEditPatient(episode: Episode) {
    return (
      this.has(PERMISSIONS.patientEdit) &&
      (this.isOwnerUser(episode.owner.clientId) ||
        this.isCurrentGroupUser(episode?.rehabInformation?.latestRehabFacility))
    );
  }

  isOwnerUser(ownerClientId: string) {
    return this.actingClient.id === ownerClientId;
  }

  isCurrentGroupUser(currentGroup: Group) {
    return this.canAccessGroup(currentGroup.id);
  }

  canAccessGroup(groupId: string) {
    return this.allowedGroupIds.includes(groupId);
  }

  get isAffiliateUser() {
    return this.clientType?.toLowerCase() === AFFILIATE;
  }

  has(permission: string): boolean {
    if (!this.permissions) return false;

    return this[permission] || this.permissions[permission] || false;
  }

  hasAll(permissions: string[]) {
    return permissions.every((permission) => this.has(permission));
  }

  hasAny(permissions: string[]) {
    return permissions.some((permission) => this.has(permission));
  }

  hasFlag(flag: string) {
    return this.flags.includes(flag);
  }

  configForGroupType(groupTypeApiName: string) {
    return this.actingClient.clientGroupTypes.find(
      (clientGroupType) => clientGroupType.groupType?.apiName === groupTypeApiName
    )?.config;
  }

  get canChangeClientScope() {
    return !!this.isAdmin || (this.client?.leafDescendants.length || 0) > 1;
  }
}
